.react-bootstrap-table-pagination-list { text-align: end; }

.gm-style-mtc {
  display: none !important;
}
/* x button on infowindow removed */
button.gm-ui-hover-effect {
  visibility: hidden;
}

/* sub sidebar */
.hoverable {
  position: static;
}

.hoverable > a:after {
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}

.mega-menu {
  display: none;
  left: 200px;
  position: absolute;
  top: 0px;
  height: 100%;
  padding-top: 98px;
}
